import { createContext, useState } from 'react'
import React from 'react'

const EdTech = createContext()




const EdTechData = ({ children }) => {
  const [edTechData, setedTechData] = useState()
  const [editblog, setEditblog] = useState(null)


  return (
    <EdTech.Provider value={{ edTechData, setedTechData,editblog, setEditblog }}>
      {children}
    </EdTech.Provider>
  )
}

export default EdTechData
export { EdTech }
