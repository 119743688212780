import React from 'react'
import { Navigate } from 'react-router-dom'
// eslint-disable-next-line react/prop-types
const Protected = ({ children }) => {
  const token = localStorage.getItem('lwb_token')

  if (!!!token) {
    return <Navigate to="/login" replace />
  }
  return children
}
export default Protected
